.cards {
    padding: 4rem;
    background: #fff;
}

h1 {
    text-align: center;
    justify-content: center;
    font-family: "Satisfy", system-ui;
    font-size: 35px;
}

h2 {
    text-align: center;
    justify-content: center;
    font-family: "Satisfy", system-ui;
    font-size: 28px;
}

p {
    text-align: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
}

p1 {
    text-align: center;
    justify-content: center;
    font-family: "Satisfy", system-ui;
    font-size: 25px;
    color: #242242;
}
p1:hover {
    font-size: 30px;
    color:cadetblue;
}

.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.cards__item {
    display: flex;
    flex: 1 1 320px;
    max-width: 360px;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards__item:hover{
    transform: scale(1.2);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;
}
.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards-logo {
    width: 50%;
    max-width: 150px;
    height: auto;
    display: block;
    margin: 0.5rem auto;
    z-index: 10;
    position: relative;
    object-fit: contain;
    opacity: 1;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 25px;
    font-weight: 900;
    font-family: "Satisfy", system-ui;
    color: #fff;
    box-sizing: border-box;
}

.cards__item__img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.cards__item__img.loaded {
    opacity: 1;
}

.cards__item__info {
    padding: 20px 30px 30px;
}

.cards__item__text {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    text-emphasis-style: bold;
}

.cards__item__p {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 600px) {
    .cards__item__pic-wrap {
        padding-top: 80%;
    }

    .cards__item__text,
    .cards__item__p {
        font-size: 13px;
    }

    .hero-logo {
        max-width: 20vw;
    }
}

@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
}